<script setup lang="ts">
import { RouteLocationNormalizedLoaded, LocationQuery } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useNewsletter } from '~/composables/useNewsletter';

const { locale, t } = useI18n();

const { apiInstance } = useShopwareContext();

const props = defineProps<{
  route: RouteLocationNormalizedLoaded;
}>();

const successSubscribe = ref(false);
const genericFail = ref(false);
const unsubscribe = ref(false);
const allUnsubscribe = ref(false);
const error = ref(false);
const isOpen = ref({
  newsletterConfirmEvent: false,
  newsletterUnsubscribeEvent: false,
  newsletterAllUnsubscribeEvent: false,
  recoverPasswordEvent: false,
});

const confirmNewsletterSubscription = async ({
  em,
  hash,
}: { em: string; hash: string } | LocationQuery) => {
  isOpen.value.newsletterConfirmEvent = true;
  await apiInstance.invoke
    .post('/store-api/newsletter/confirm', {
      em,
      hash,
    })
    .then(() => {
      successSubscribe.value = true;
    })
    .catch((e) => {
      error.value = true;
      genericFail.value = true;
      console.error(e);
      // error.value = e as any; // TODO: add UI error display
    });
};
const { newsletterUnsubscribe } = useNewsletter();
const unsubscribeAllNewsletter = async (email: any) => {
  isOpen.value.newsletterAllUnsubscribeEvent = true;
  await apiInstance.invoke
    .post('/store-api/crossengage/newsletter/uwg7-unsubscribe', { email })
    .then(() => {
      allUnsubscribe.value = true;
    })
    .catch((e) => {
      console.error(e);
      error.value = true;
      genericFail.value = true;
    });
};

const unsubscribeNewsletter = async (email: string, source: string) => {
  isOpen.value.newsletterUnsubscribeEvent = true;
  await newsletterUnsubscribe(email, source)
    .then(() => {
      unsubscribe.value = true;
    })
    .catch((e) => {
      console.error(e);
      error.value = true;
      genericFail.value = true;
    });
};

const recoverPassword = async () => {
  isOpen.value.recoverPasswordEvent = true;
};

const newsletterConfirmEvent = computed(
  () => props.route?.query?.event === 'newsletter.confirm'
);
const newsletterUnsubscribeEvent = computed(
  () => props.route?.query?.event === 'newsletter.unsubscribe'
);
const newsletterAllUnsubscribeEvent = computed(
  () => props.route?.query?.event === 'newsletter.uwg7unsubscribe'
);

const recoverPasswordEvent = computed(
  () => props.route?.query?.event === 'password.recover'
);

onMounted(() => {
  let email = props.route.query.email
    ? props.route.query.email.toString().replace(' ', '+')
    : '';
  email = encodeURIComponent(email);
  const source = props.route.query.source?.toString() ?? '';
  if (props.route?.query?.event === 'newsletter.confirm') {
    confirmNewsletterSubscription(props.route.query);
  }

  if (props.route?.query?.event === 'newsletter.uwg7unsubscribe') {
    unsubscribeAllNewsletter(email);
  }

  if (props.route?.query?.event === 'newsletter.unsubscribe') {
    unsubscribeNewsletter(email, source);
  }

  // confirmNewsletterSubscription(props.route.query);
  recoverPassword();
});
</script>

<template>
  <div>
    <NewsletterModal
      v-if="
        (newsletterConfirmEvent && isOpen.newsletterConfirmEvent) ||
        (newsletterUnsubscribeEvent && isOpen.newsletterUnsubscribeEvent) ||
        (newsletterAllUnsubscribeEvent && isOpen.newsletterAllUnsubscribeEvent)
      "
      :error="error"
      :subscribe-confirm="successSubscribe"
      :all-unsubscribe="allUnsubscribe"
      :unsubscribe="unsubscribe"
      :generic-fail="genericFail"
    />
    <ForgotPasswordModal
      v-if="recoverPasswordEvent && isOpen.recoverPasswordEvent"
      centered
      :title="t('components.resetPasswordForm.resetModal.successTitle')"
      :content="t('components.resetPasswordForm.resetModal.successContent')"
    />
  </div>
</template>
